import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import { Provider } from "react-redux";
import "./i18n";
import NavigationList from "./components/shared/navigation/NavigationList";
// import MasterLayout from '~/components/layouts/MasterLayout';
import MasterLayout from "./components/layouts/MasterLayout";
import PageLoader from "./components/elements/common/PageLoader";
import { BackTop } from "antd";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    <NavigationList />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
