import React, { Component } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const mapStyles = {
  width: "100%",
  height: "400px", // Set a fixed height for the map container
};

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false, // Hides or shows the InfoWindow
    activeMarker: {}, // Shows the active marker upon click
    selectedPlace: {}, // Shows the InfoWindow to the selected place upon a marker
  };

  // Handler for when marker is dragged
  onMarkerDragEnd = (t, map, coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    console.log("New Marker Location:", { lat, lng });
    this.props.onMarkerDragEnd(lat, lng);

    // Perform actions with lat and lng, such as storing them in state or sending them to an API
  };

  render() {
    const { google, location } = this.props;

    // Check if location.latitude and location.longitude are valid numbers
    if (isNaN(location?.lat) || isNaN(location?.lng)) {
      return <div>Error: Invalid location coordinates</div>;
    }

    return (
      <div style={{ position: 'relative', width: '100%', height: '400px', marginBottom: '20px' }}>
        <Map
          google={google}
          zoom={5}
          style={mapStyles}
          initialCenter={{
            lat: location.lat,
            lng: location.lng,
          }}
          center={{
            lat: location.lat,
            lng: location.lng,
          }}
        >
          <Marker
            name={"ISS"}
            position={location}
            draggable={true} // Enable user interaction to move the marker
            icon={{
              url: "https://icons.iconarchive.com/icons/goodstuff-no-nonsense/free-space/512/international-space-station-icon.png", // URL of the downloaded image
              anchor: new google.maps.Point(26, 29),
              scaledSize: new google.maps.Size(64, 64), // Size of the custom marker icon
            }}
            onDragend={this.onMarkerDragEnd} // Handler for when marker is dragged
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyD07E1VvpsN_0FvsmKAj4nK9GnLq-9jtj8",
})(MapContainer);
