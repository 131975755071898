import baseUrl from "../../utilities/Api/baseUrl";
import {
  POST_NEW_ITEM,
  DELETE_NEW_ITEM,
  GET_BY_ID_ITEM,
  EDIT_NEW_ITEM,
} from "../Type";
import { notification } from "antd";

const AddNewItem = (data) => {
  console.log("kkkkkkkkkkk");

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  console.log(config);
  if (config == undefined) {
    notification.error({
      message: "Error",
    });
  }
  return async (dispatch) => {
    try {
      let res = await baseUrl.post(`api/auth/add-item`, data, config);
      if (res.status == 401) {
        console.log("kkkkkkkkkkk");
      }
      console.log(res);
      dispatch({ type: POST_NEW_ITEM, payload: res });
      if (res.data.status === false) {
        notification.error({
          message: "Error",
          description: res.data.msg,
        });
      } else {
        notification.success({
          message: "Success",
          description: res.data.msg,
        });
      }
    } catch (e) {
      dispatch({ type: POST_NEW_ITEM, payload: e.res });
    }
  };
};
const DeleteItem = (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return async (dispatch) => {
    try {
      let res = await baseUrl.delete(`api/auth/delete-item/${id}`, config);
      dispatch({ type: DELETE_NEW_ITEM, payload: res });
      if (res.data.status === false) {
        notification.error({
          message: "Error",
          description: res.data.msg,
        });
      } else {
        notification.success({
          message: "Success",
          description: res.data.msg,
        });
      }
    } catch (e) {
      dispatch({ type: DELETE_NEW_ITEM, payload: e.res });
    }
  };
};
const EditItem = (id, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  console.log(data);
  return async (dispatch) => {
    try {
      let res = await baseUrl.post(`api/auth/update-item/${id}`, data, config);
      dispatch({ type: EDIT_NEW_ITEM, payload: res });
      if (res.data.status === false) {
        notification.error({
          message: "Error",
          description: res.data.msg,
        });
      } else {
        notification.success({
          message: "Success",
          description: res.data.msg,
        });
      }
    } catch (e) {
      dispatch({ type: EDIT_NEW_ITEM, payload: e.res });
    }
  };
};

const GetByidItem = (id, lang, latitude, longitude) => {
  console.log(id)

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  lang = lang === "en" ? "ar" : "en"; // Assign the result back to lang variable
  return async (dispatch) => {
    try {
      let res = await baseUrl.get(`api/app/item/${id.id}/${id.lang}`, {
        params: {
          current_latitude: id.latitude,
          current_longitude: id.longitude,
        },
        ...config,
      });

      dispatch({ type: GET_BY_ID_ITEM, payload: res });
      console.log(res.data);
      if (res.data.status === false) {
        notification.error({
          message: "Error",
          description: res.data.msg,
        });
      }
    } catch (e) {
      dispatch({ type: GET_BY_ID_ITEM, payload: e.res });
    }
  };
};

export { AddNewItem, DeleteItem, EditItem, GetByidItem };
