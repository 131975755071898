import React from 'react';

const HeaderTopTitle = ({title}) => {
    return (
        <div>
            <div className="ps-container">
                <h3>{title}</h3>
            </div>
        </div>
    );
};

export default HeaderTopTitle;
