import React from 'react';

const MegaMenu = ({ source }) => {
    let megaContentView;

    if (source) {
        megaContentView = (
            <div className="mega-menu__column" key={source.name}>
                <h4>{source.name}</h4>
                <ul className="mega-menu__list">
                    {source.subcategories.map((subItem) => (
                        <li key={subItem.name}>
                            <a href={`/${source.name}/${subItem.id}`}>
                                {subItem.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
    return (
        <li className="menu-item-has-children has-mega-menu" style={{textAlign:'right'}}>
            <a href={source.url !== '' ? source.url : '/'}>
                {source.icon && <i className={source.icon}></i>}
                {source.name}
            </a>
            <div className="mega-menu">{megaContentView}</div>
        </li>
    );
};

export default MegaMenu;
